import { Controller } from "stimulus"

import "../../css/expand.css"

export default class extends Controller {
  static targets = ["container", "button", "buttonOpen", "buttonClose"]

  connect() {
    this.set(this.isOpen)
    this.containerTarget.classList.add("expand-container")
  }

  get isOpen() { return this.data.has("open") }

  set(shouldOpen) { shouldOpen ? this.open() : this.close() }

  toggle() { this.set(!this.isOpen) }

  open() {
    this.containerTarget.classList.remove("expand-hidden")
    this.hasButtonTarget && (this.buttonTarget.textContent = "← Show Less")
    this.hasButtonOpenTarget && (this.buttonOpenTarget.classList.add("expand-button-hidden"))
    this.hasButtonCloseTarget && (this.buttonCloseTarget.classList.remove("expand-button-hidden"))
    this.data.set("open")
  }

  close() {
    this.containerTarget.classList.add("expand-hidden")
    this.hasButtonTarget && (this.buttonTarget.textContent = "Show More →")
    this.hasButtonOpenTarget && (this.buttonOpenTarget.classList.remove("expand-button-hidden"))
    this.hasButtonCloseTarget && (this.buttonCloseTarget.classList.add("expand-button-hidden"))
    this.data.delete("open")
  }
}
