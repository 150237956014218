if (module.hot) {
  module.hot.accept();
}

import { Application } from "stimulus"
import controllers from "./controllers/*_controller.js"

const application = Application.start()

Object.entries(controllers).forEach(([name, controller]) => {
  application.register(name, controller.default)
})
